// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { attr, belongsTo, hasMany } from '@ember-data/model';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';

// eslint-disable-next-line ember/no-mixins
import Userable from '../mixins/userable';
import Model from './model';

const { PromiseArray } = DS;

export const STATE = Object.freeze({
  active: 'active',
  ongoing: 'ongoing',
  scheduled: 'scheduled',
  ended: 'ended',
  idle: 'idle',
});

function today() {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
}

export default class MissionModel extends Model.extend(Userable) {
  // SETUP

  @service router;
  @service store;
  @service intl;
  @service session;

  validations = {
    title: {
      presence: {
        message: function (_, __, record) {
          return record.intl.t('models.mission.validations.title.presence');
        },
      },
    },
  };

  // RELATIONSHIPS

  @belongsTo('course', { async: true, inverse: 'missions' }) course;

  @hasMany('custom-content', { async: true, inverse: null }) customContents;

  @hasMany('user', { async: true, inverse: null }) receivers;

  @hasMany('file', { async: true, inverse: null }) files;

  @hasMany('entity', { async: true, inverse: null }) linkedEntities;

  // ATTRIBUTES

  @attr('string') title;

  @attr('string', { defaultValue: '' }) description;

  @attr('boolean', { defaultValue: true }) active;

  @attr('boolean', { defaultValue: true }) showDescription;

  @attr('boolean', { defaultValue: false }) hideSolutions;

  @attr('boolean', { defaultValue: false }) showKey;

  @attr('boolean', { defaultValue: false }) hasReceivers;

  @attr('only-date') startDate;

  @attr('only-date') endDate;

  @attr('array', {
    defaultValue() {
      return [];
    },
  })
  sortedCustomContentIds;

  get isTargeted() {
    return this.hasReceivers;
  }

  @computed('customContents.[]', 'sortedCustomContentIds')
  get sortedCustomContents() {
    const promise = Promise.resolve(this.customContents).then(
      (customContents) => {
        const sortedCustomContentIds = this.sortedCustomContentIds;

        if (sortedCustomContentIds && sortedCustomContentIds.length > 0) {
          return sortedCustomContentIds
            .map((id) => this.store.peekRecord('custom-content', id))
            .compact();
        }

        return customContents ?? [];
      }
    );

    return PromiseArray.create({ promise });
  }

  get hasCustomContents() {
    return this.hasMany('customContents').ids().length > 0;
  }

  @computed('sortedCustomContents.@each.entity')
  get entities() {
    const promise = Promise.resolve(this.sortedCustomContents).then(
      (customContents) => {
        return Promise.all(
          customContents.rejectBy('isDeleted').mapBy('entity')
        );
      }
    );

    return PromiseArray.create({ promise });
  }

  get hasDescription() {
    return isPresent(this.description);
  }

  get isScheduled() {
    return !!this.startDate;
  }

  get hasStarted() {
    if (!this.startDate) {
      return false;
    }

    return today() >= this.startDate;
  }

  get hasEnded() {
    if (!this.endDate) {
      return false;
    }

    return today() > this.endDate;
  }

  get isOngoing() {
    return this.hasStarted && !this.hasEnded;
  }

  get isVisible() {
    return this.active || this.isOngoing;
  }

  get canBeAudited() {
    return !this.isScheduled || (this.isScheduled && this.hasStarted);
  }

  get state() {
    if (this.active) {
      return STATE.active;
    } else if (this.isOngoing) {
      return STATE.ongoing;
    } else if (this.isScheduled) {
      return STATE.scheduled;
    } else if (this.hasEnded) {
      return STATE.ended;
    }
    return STATE.idle;
  }

  get daysUntil() {
    if (this.isScheduled) {
      const today = new Date();
      const time = this.startDate - today.getTime();
      return Math.max(Math.ceil(time / (1000 * 3600 * 24)), 0);
    }
    return null;
  }

  get daysLeft() {
    if (this.isOngoing) {
      if (this.endDate) {
        const today = new Date();
        const time = this.endDate - today.getTime();
        return Math.max(Math.ceil(time / (1000 * 3600 * 24)), 0);
      }

      return Infinity;
    }

    return null;
  }

  get isAccessible() {
    const user = this.session.user;

    if (user.isTeacher) {
      return true;
    }

    if (this.isTargeted) {
      return this.receivers.includes(user);
    }

    return true;
  }

  get shareableLink() {
    const url = this.router.urlFor('master.missions', this.id);
    return window.location.origin + url;
  }

  // ACTIONS

  @action
  async newCustomContent(fields) {
    const course = await this.course;
    return this.store.createRecord('custom-content', {
      ...fields,
      course,
      user: this.session.user,
      missions: [this],
      includedEntities: [], // NOTE This is needed.
    });
  }

  @action
  addCustomContent(customContent) {
    this.customContents.addObject(customContent);
    this.sortedCustomContentIds.addObject(customContent.id);
  }

  @action
  removeCustomContent(customContent) {
    this.customContents.removeObject(customContent);
    this.sortedCustomContentIds.removeObject(customContent.id);
  }

  @action
  async addNewCustomContent(fields) {
    const customContent = await this.newCustomContent(fields);
    this.addCustomContent(customContent);
    return customContent;
  }
}
