import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class MasterInteractivesPracticesIndexRoute extends Route {
  // SETUP

  @service router;

  // HOOKS

  beforeModel() {
    const { activeExercise } = this.modelFor('master.interactives.practices');

    if (activeExercise) {
      return this.router.replaceWith(
        'master.interactives.practices.show',
        activeExercise.id
      );
    }

    return this.router.replaceWith('master.interactives');
  }
}
