import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

const PRESETS = {
  french: {
    common: [],
    upper: ['À', 'Â', 'Ç', 'É', 'È', 'Ê', 'Ë', 'Î', 'Ï', 'Ù', 'Û', 'Ô', 'Œ'],
    lower: ['à', 'â', 'ç', 'é', 'è', 'ê', 'ë', 'î', 'ï', 'ù', 'û', 'ô', 'œ'],
  },
  german: {
    common: ['β'],
    upper: ['Ü'],
    lower: ['ü'],
  },
  italian: {
    common: [],
    upper: ['À', 'È', 'É', 'Ì', 'Í', 'Ò', 'Ó', 'Ù', 'Ú'],
    lower: ['à', 'è', 'é', 'ì', 'í', 'ò', 'ó', 'ù', 'ú'],
  },
  spanish: {
    common: ['¡', '!', '¿', '?'],
    upper: ['Á', 'É', 'Ó', 'Í', 'Ú', 'Ü', 'Ñ'],
    lower: ['á', 'é', 'ó', 'í', 'ú', 'ü', 'ñ'],
  },
  swedish: {
    common: [],
    upper: ['Å', 'Ä', 'Ö'],
    lower: ['å', 'ä', 'ö'],
  },
};

export default class SpecialKeyboardComponent extends Component {
  @tracked lowercased = this.characterSet?.lower?.length ? true : false;

  @tracked disabled = false;

  get characterSet() {
    return this.args.characters || PRESETS[this.args.preset];
  }

  get lowerCaseLetters() {
    return this.characterSet?.lower;
  }

  get upperCaseLetters() {
    return this.characterSet?.upper;
  }

  get commonLetters() {
    return this.characterSet?.common;
  }

  get showShiftKey() {
    return this.lowerCaseLetters?.length && this.upperCaseLetters?.length;
  }

  get shiftIcon() {
    return 'shift';
  }

  get shiftAlternative() {
    return this.lowercased ? 'alternative' : 'default';
  }

  @action
  insertChar(char) {
    document.execCommand('insertText', false, char);
  }

  @action
  toggleCase() {
    this.lowercased = !this.lowercased;
  }

  @action
  preventBlur(e) {
    e.preventDefault();
  }

  @action
  detectFocus(event) {
    this.setEnabledIfFocus(event.srcElement);
  }

  @action
  detectBlur() {
    this.disabled = true;
  }

  setEnabledIfFocus(element) {
    const inputs = ['input', 'textarea'];

    if (inputs.includes(element.tagName?.toLowerCase())) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }

  // Life cycle hooks

  constructor() {
    super(...arguments);
    window.addEventListener('focus', this.detectFocus, true);
    window.addEventListener('blur', this.detectBlur, true);

    this.setEnabledIfFocus(document.activeElement);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    window.removeEventListener('focus', this.detectFocus, true);
    window.removeEventListener('blur', this.detectBlur, true);
  }
}
