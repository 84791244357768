import { addListener, removeListener, sendEvent } from '@ember/object/events';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import config from 'babel/config/environment';
import { emptyAnswer, getCorrectAnswers } from 'babel/utils/assignments';
import { getOriginalAnswer } from 'babel/utils/get-answer';
import { chunk } from 'compton/utils/array';
import { task, timeout, waitForProperty } from 'ember-concurrency';
import { all, resolve } from 'rsvp';

const AUTOSAVE_TIME = 2000;

export default class AssignmentEventsService extends Service {
  @service ajax;

  @service router;

  @service session;

  @service store;

  @service missionMode;

  @tracked focusedExercise = null;

  sent = {};

  on(event, method) {
    addListener(this, event, method);
  }

  off(event, method) {
    removeListener(this, event, method);
  }

  trigger() {
    const args = Array.prototype.slice.call(arguments);
    const event = args.shift();

    sendEvent(this, event, args);
  }

  @task({ enqueue: true })
  *saveAnswer(context, answer, action) {
    if (!context.isDestroyed) {
      context.localStatus = 'saving';
    }

    if (this.submitAnswer.isRunning) {
      this.autoSaveAnswer.cancelAll();
    }

    const resolvedAnswer = yield answer;

    resolvedAnswer.updated = new Date();

    yield resolvedAnswer.save({
      adapterOptions: {
        action,
        ignoreResponsePayload: true,
      },
    });

    if (!context.isDestroyed) {
      context.localStatus = null;
    }
  }

  @task({ restartable: true })
  *autoSaveAnswer(context, answer) {
    context.localStatus = 'editing';

    yield timeout(AUTOSAVE_TIME);

    yield this.saveAnswer.perform(context, answer);
  }

  @task
  *submitAnswer(context, answer) {
    yield waitForProperty(
      context,
      'localStatus',
      (local) => local !== 'saving'
    );

    yield this.saveAnswer.perform(context, answer, 'submit');

    const versionsRelation = answer.hasMany('versions');

    yield versionsRelation.load();

    const numVersions = yield answer.versions.length;

    if (numVersions === 0) {
      yield answer.reload();
    }

    yield versionsRelation.reload();
  }

  updateInteractiveForCollection(collection, interactive) {
    const collectionId = collection.id;
    const userId = this.session.user.id;
    const interactiveKey = collectionId + userId;

    this.sent[interactiveKey] = interactive;

    this.trigger('interactive-updated', collectionId);
  }

  async getOrCreateInteractivesForCollections(collections, activeExercises) {
    const interactives =
      await this._fetchOrCreateInteractivesForCollections(collections);

    return all(
      collections.map(async (collection, index) => {
        const collectionId = collection.id;
        const userId = this.session.user.id;

        const interactive = interactives[index];

        if (!interactive) return {};

        const entity = await interactive.entity;
        let children = await entity.children;
        children = await this.missionMode.allowedEntities(children);

        let activeExercise = activeExercises?.[index];

        if (!activeExercise && !collection.isDiagnosis) {
          const currentExercise = await interactive.currentExercise;

          if (currentExercise && children.includes(currentExercise)) {
            activeExercise = currentExercise;
          } else {
            activeExercise = children.firstObject;
          }
        }

        let activeAnswer;

        if (activeExercise && !children.includes(activeExercise)) {
          activeExercise = children.firstObject;
        }

        if (activeExercise) {
          const answerKey = collectionId + userId + activeExercise.id;

          if (!this.sent[answerKey]) {
            this.sent[answerKey] = this.getOrCreateAnswerForExercise(
              activeExercise,
              interactive.answers
            );
          }

          activeAnswer = await this.sent[answerKey];

          if (!interactive.answers.includes(activeAnswer)) {
            // The answer was created previously but not saved in the interactives answers
            interactive.answers.addObject(activeAnswer);
          }

          if (interactive.currentExercise?.id !== activeExercise.id) {
            interactive.currentExercise = activeExercise;
          }

          if (activeAnswer.isNew && !activeAnswer.isSaving) {
            await activeAnswer.save();
          }

          if (!interactive.isSaving && !collection.isTeacherCollection) {
            // Teacher collections do not have a persisted id,
            // we therefore cannot save the interactive as the id
            // will not be the same on the next page load.
            interactive.save();
          }
        }

        return { interactive, activeAnswer, activeExercise };
      })
    );
  }

  async getOrCreateInteractiveForCollection(collection, activeExercise) {
    const items = await this.getOrCreateInteractivesForCollections(
      [collection],
      [activeExercise]
    );

    return items[0];
  }

  async createInteractive(collection, attempt, answers = []) {
    const [ancestors, user] = await all([
      collection.getParents(),
      this.session.user,
    ]);

    const content =
      ancestors.findBy('type', 'contents') ||
      ancestors.findBy('type', 'interactives');
    const area = ancestors.findBy('type', 'areas');
    const book = ancestors.findBy('type', 'books');

    const correctAnswers = getCorrectAnswers(answers);
    const correctExercises = await all(
      correctAnswers.map((answer) => answer.entity)
    );

    return this.store.createRecord('interactive', {
      answers,
      document_id: collection.document_id,
      started: new Date(),
      attempt,
      type: 'practice',
      entity: collection,
      book,
      area,
      content,
      user,
      score: correctAnswers.length,
      correctExercises,
    });
  }

  _keyForCollection(collection) {
    const collectionId = collection.id;
    const userId = this.session.user.id;

    return collectionId + userId;
  }

  async _fetchInteractivesForCollections(collections) {
    let path;

    if (collections.length === 1) {
      // We use this endpoint when there is only one collection
      // as this endpoint is cached. In many cases the code will
      // call the methods once for each collection rather than
      // calling for all collections at once. This provides better
      // performance for those cases.
      path = `/api/v1/meta/interactives/active/${collections[0].id}`;
    } else {
      const collectionIds = collections.map((collection) => collection.id);
      const params = new URLSearchParams();

      params.append('user_id[]', [this.session.user.id]);
      collectionIds.map((id) => params.append('node_id[]', id));

      path = `/api/v1/meta/interactives/active?${params.toString()}`;
    }

    let payload;

    try {
      payload = await this.ajax.request(`${config.endpoint}${path}`, true);
      this.store.pushPayload('interactive', payload);
    } catch (err) {
      // Continue with create
    }

    return all(
      collections.map(async (collection) => {
        let interactive, interactivePayload;

        if (Array.isArray(payload?.data)) {
          interactivePayload = payload?.data?.find(
            (item) => item.relationships.entity.data.id === collection.id
          );
        } else {
          interactivePayload = payload?.data;
        }

        if (interactivePayload?.id) {
          interactive = this.store.peekRecord(
            'interactive',
            interactivePayload?.id
          );
        }

        if (!interactive) {
          if (!collection.isDiagnosis) {
            const exercises = await resolve(collection.children);
            const answers = await this.getAnswersForExercises(exercises);

            interactive = await this.createInteractive(collection, 1, answers);
          }
        } else {
          await all([
            interactive.answers,
            interactive.entity,
            interactive.currentExercise,
            interactive.user,
          ]);
        }

        return { collectionId: collection.id, interactive };
      })
    );
  }

  async _fetchOrCreateInteractivesForCollections(collections) {
    // Find the collections that have not already been fetched.
    const collectionsToFetch = collections.filter(
      (collection) => !this.sent[this._keyForCollection(collection)]
    );

    if (collectionsToFetch.length > 0) {
      // Chunk the collections into groups of 50 to avoide generating
      // a too large URL.
      const chunks = chunk(collectionsToFetch, 50);

      await all(
        chunks.map((chunk) => {
          // Fetch the interactives for all collections in the chunk.
          const promise = this._fetchInteractivesForCollections(chunk);

          chunk.map((collection) => {
            // Store the promise as result for each collection in this.sent
            this.sent[this._keyForCollection(collection)] = promise.then(
              (interactives) =>
                interactives.find((item) => item.collectionId === collection.id)
                  ?.interactive
            );
          });

          return promise;
        })
      );
    }

    return all(
      collections.map(
        (collection) => this.sent[this._keyForCollection(collection)]
      )
    );
  }

  async getAnswersForExercises(exercises, useCache = true) {
    const key = exercises.mapBy('id').join(',');

    if (!key) return [];
    if (useCache && this.sent[key]) return this.sent[key];

    const query = { filter: {} };

    exercises.forEach((exercise) => {
      if (exercise.isTeacherAssignment) {
        if (!query.filter.teacher_assignment_id) {
          query.filter.teacher_assignment_id = [];
        }

        query.filter.teacher_assignment_id.push(exercise.teacherAssignment.id);
      } else {
        if (!query.filter.node_id) {
          query.filter.node_id = [];
        }

        query.filter.node_id.push(exercise.id);
      }
    });

    this.sent[key] = this.store
      .query('answer', query)
      .then((answers) => answers.slice());

    return this.sent[key];
  }

  async handleTeacherAssignmentsAddedToCollection(exercises) {
    const answers = await this.getAnswersForExercises(exercises, true);

    if (answers.length === 0) return;

    const collection = await resolve(exercises[0].parent);
    const { interactive } =
      await this.getOrCreateInteractiveForCollection(collection);

    answers.forEach((answer) => {
      if (!interactive.answers.includes(answer)) {
        interactive.answers.addObject(answer);
      }
    });
  }

  async getOrCreateAnswerForExercise(exercise, answers) {
    let answer = getOriginalAnswer(exercise, answers);

    if (!answer) {
      let teacherAssignment, collection, content, area, book;

      if (exercise.isTeacherAssignment) {
        [teacherAssignment, collection] = await all([
          exercise.teacherAssignment,
          exercise.parent,
        ]);
        content = await resolve(collection.parent);
        [area, book] = await all([content.parent, content.book]);
      } else {
        const ancestors = await resolve(exercise.getParents());

        collection =
          ancestors.findBy('type', 'collections') ||
          ancestors.findBy('type', 'sections');

        content =
          ancestors.findBy('type', 'contents') ||
          ancestors.findBy('type', 'interactives');
        area = ancestors.findBy('type', 'areas');
        book = ancestors.findBy('type', 'books');
      }

      let type;

      if (collection.template === 'quiz') {
        type = 'excercise_competition';
      } else if (collection.isDiagnosis) {
        type = 'diagnosis';
      } else if (exercise.isTeacherAssignment) {
        type = 'teacher_assignment';
      } else {
        type = 'standard';
      }

      answer = this.store.createRecord('answer', {
        teacherAssignment,
        collection,
        content,
        area,
        book,
        entity: exercise,
        type,
        user: this.session.user,
        assignment_title: exercise.title,
        document_id: exercise.document_id,
        status: 'not-started',
      });

      answers.addObject(answer);
    }

    const exerciseAssignments = exercise.body?.exercise?.assignments;

    if (exerciseAssignments) {
      if (
        !answer.assignments ||
        answer.assignments.length !== exerciseAssignments.length
      ) {
        answer.assignments = exerciseAssignments.map((assignment) =>
          emptyAnswer(assignment)
        );
      }
    }

    return answer;
  }

  async carouselAttemptExercises(interactive) {
    const [collection, correctExercises] = await all([
      interactive.entity,
      interactive.correctExercises,
    ]);
    const exercises = await resolve(collection.children);

    return exercises.filter((exercise) => !correctExercises.includes(exercise));
  }

  async carouselNextExercise(interactive) {
    const attemptExercises = await this.carouselAttemptExercises(interactive);

    let index;
    let currentExercise = await resolve(interactive.currentExercise);

    if (!currentExercise || !attemptExercises.includes(currentExercise)) {
      index = 0;
    } else {
      index = attemptExercises.indexOf(currentExercise) + 1;
    }

    const remainingExercises = attemptExercises.slice(index);

    if (remainingExercises.length > 0) {
      return remainingExercises[0];
    } else {
      return null;
    }
  }

  async carouselOpenNextExercise(interactive) {
    const next = await this.carouselNextExercise(interactive);

    if (next) {
      interactive.currentExercise = next;
      return this.refreshCarouselRoute(interactive);
    }
  }

  async carouselDone(interactive) {
    const answers = await resolve(interactive.answers);
    const correctAnswers = getCorrectAnswers(answers);

    interactive.ended = new Date();
    interactive.score = correctAnswers.length;

    await interactive.save();
  }

  async newCarouselAttempt(previousAttempt, resetAllAnswers = false) {
    const collection = await resolve(previousAttempt.entity);
    const exercises = await resolve(collection.children);
    const exerciseIds = exercises.map((exercise) => exercise.id);
    const attempt = previousAttempt.attempt + 1;

    const answers = await resolve(previousAttempt.answers).then((arr) =>
      arr
        .slice()
        .filter((answer) =>
          exerciseIds.includes(answer.belongsTo('entity').id())
        )
    );
    const correctAnswers = getCorrectAnswers(answers);
    const previousAttemptAnswers = [];

    await all(
      answers.map(async (answer) => {
        const [versions, exercise] = await all([
          answer.versions,
          answer.entity,
        ]);

        if (versions.length > 0) {
          previousAttemptAnswers.push(versions.sortBy('created').pop());
        } else {
          previousAttemptAnswers.push(answer);
        }

        if (resetAllAnswers || !correctAnswers.includes(answer)) {
          await this.resetAnswer(answer, exercise);
        }
      })
    );

    previousAttempt.answers = previousAttemptAnswers;

    await previousAttempt.save();

    const interactive = await this.createInteractive(
      collection,
      attempt,
      answers
    );

    interactive.currentExercise = await this.carouselNextExercise(interactive);

    await interactive.save();

    this.updateInteractiveForCollection(collection, interactive);

    try {
      await this.refreshCarouselRoute(interactive);
    } catch {
      // Failed to refresh route, we ignore this
    }
  }

  async resetAnswer(answer, exercise) {
    answer.assignments = exercise?.body?.exercise?.assignments?.map(
      (assignment) => emptyAnswer(assignment)
    );

    answer.status = 'not-started';

    return this.saveAnswer.perform(this, answer);
  }

  async refreshCarouselRoute(interactive) {
    const collection = await resolve(interactive.entity);
    const parent = await resolve(collection.parent);

    if (parent.type === 'contents' && collection.space === 'content') {
      // When in content space of contents refreshing the route is not enough
      // since most data is loaded in the controller or components. We
      // therefore do a transition on the exercise query param to trigger
      // tracking updates.
      const currentExercise = await resolve(interactive.currentExercise);
      const currentParam = this.router.currentRoute.queryParams.exercise;

      let exercise = null;

      if (!interactive.ended && currentExercise.id !== currentParam) {
        exercise = currentExercise.id;
      }

      return this.router.transitionTo({ queryParams: { exercise } });
    }

    return this.router.refresh(this.router.currentRouteName);
  }

  focusExercise(exercise) {
    this.focusedExercise = exercise;
  }

  resetExerciseFocus() {
    this.focusedExercise = null;
  }
}
