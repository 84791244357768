import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { MissingContentError } from 'babel/utils/errors';
import getCollectionData from 'babel/utils/get-collection-data';
import { reject } from 'rsvp';

export default class MasterInteractivesPracticesRoute extends Route {
  @service assignmentEvents;

  @service store;

  async model({ practice_id }) {
    let collection;

    try {
      collection = await this.store.findRecord('entity', practice_id);
    } catch (err) {
      return reject(
        new MissingContentError(err.message, 'master.interactives')
      );
    }

    let { parent, book, exercises, editor } = await getCollectionData(
      this.store,
      collection
    );

    const { activeExercise } =
      await this.assignmentEvents.getOrCreateInteractiveForCollection(
        collection
      );

    return {
      activeExercise,
      book,
      collection,
      editor,
      exercises,
      parent,
    };
  }
}
