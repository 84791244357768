import { action } from '@ember/object';
import { service } from '@ember/service';
import EntityContextRoute from 'babel/routes/entity-context-route';

export default class MasterInteractivesIndexRoute extends EntityContextRoute {
  @service router;

  @service store;

  @service speaker;

  @service pageviews;

  @service assignmentEvents;

  model() {
    return this.modelFor('master.interactives');
  }

  async afterModel({ model }, transition) {
    const { book, parent } = model;
    this.pageviews.logPageView(model, book, parent);

    if (model.children.length === 1) {
      const exerciseId = transition?.to?.params?.practice_show_id;

      if (exerciseId) {
        return this.router.transitionTo(
          'master.interactives.practices.show',
          exerciseId
        );
      }

      const collectionId = model.children?.firstObject?.id;

      if (collectionId) {
        const collection = await this.store.findRecord('entity', collectionId);
        const targetRoute = collection.isDiagnosis
          ? 'master.interactives.diagnoses.index'
          : 'master.interactives.practices.index';

        return this.router.transitionTo(targetRoute, collectionId);
      }
    } else {
      await this.assignmentEvents.getOrCreateInteractivesForCollections(
        model.children
      );
    }
  }

  setupController(controller, { model }) {
    super.setupController(...arguments);
    controller.model = model;
  }

  @action
  didTransition() {
    this.speaker.resetSpeakerActive();
    return true;
  }
}
