import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';
// eslint-disable-next-line ember/no-mixins
import EntityRouteSupport from 'babel/mixins/entity-route-support';
import refreshRouteAndNotifyExerciseChange from 'babel/utils/refresh-route-and-notify-exercise-change';

export default class Interatives extends Route.extend(EntityRouteSupport) {
  @service contextHelper;

  @service pusher;

  @service router;

  includeGrandchildren = true;

  setupController(controller, { closeUrl }) {
    super.setupController(...arguments);
    controller.set('closeUrl', closeUrl);
    this.contextHelper.setCloseUrl(closeUrl);
    this.pusher.on('custom-content', this.reload);
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      controller.set('closeUrl', null);
      controller.set('customContentId', null);
      controller.set('missionId', null);
      this.contextHelper.setCloseUrl(null);
    }

    this.pusher.off('custom-content', this.reload);
  }

  @action
  reload() {
    refreshRouteAndNotifyExerciseChange(
      this.router,
      this.contextHelper,
      'master.interactives'
    );
  }
}
