import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"entity-thumbnail {{if this.isProgress \" entity-thumbnail--progress\"}}\" ...attributes>\n  {{#if this.isAvatar}}\n    <XAwait @value={{@avatarUser}} as |avatarUser|>\n      <DcAvatar\n        @size=\"x-small\"\n        @user={{avatarUser}}\n      />\n    </XAwait>\n  {{else if this.isProgress}}\n    <EntityProgress @model={{@model}} />\n  {{else if this.isIcon}}\n    <DcIcon @name={{this.icon}}/>\n  {{else}}\n    {{@index}}\n  {{/if}}\n</div>\n", {"contents":"<div class=\"entity-thumbnail {{if this.isProgress \" entity-thumbnail--progress\"}}\" ...attributes>\n  {{#if this.isAvatar}}\n    <XAwait @value={{@avatarUser}} as |avatarUser|>\n      <DcAvatar\n        @size=\"x-small\"\n        @user={{avatarUser}}\n      />\n    </XAwait>\n  {{else if this.isProgress}}\n    <EntityProgress @model={{@model}} />\n  {{else if this.isIcon}}\n    <DcIcon @name={{this.icon}}/>\n  {{else}}\n    {{@index}}\n  {{/if}}\n</div>\n","moduleName":"babel/components/entity-thumbnail/index.hbs","parseOptions":{"srcName":"babel/components/entity-thumbnail/index.hbs"}});
import Component from '@glimmer/component';

export default class EntityThumbnailComponent extends Component {
  get isAvatar() {
    return this.args.avatarUser?.get('initials')?.length > 0;
  }

  get isProgress() {
    return (
      this.args.type === 'collections' ||
      (this.args.type === 'sections' && this.args.model?.childrenSum > 0)
    );
  }

  get isIcon() {
    if (
      this.args.type === 'sections' &&
      this.args.model?.hideSectionSortNumbers
    ) {
      return true;
    }

    return ['collections', 'glossaries', 'concepts'].includes(this.args.type);
  }

  get icon() {
    switch (this.args.type) {
      case 'collections':
        return 'collection';
      case 'glossaries':
        return 'glossaries';
      case 'concepts':
        return 'glossaries';
      case 'sections':
        return 'section';
      default:
        return null;
    }
  }
}
