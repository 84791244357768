import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class={{if (not-eq @entity.id @result.entity.children.lastObject.id) \"bb1\"}}>\n  <UiButton\n    @appearance=\"link\"\n    @onClick={{this.openAudit}}\n    class=\"py1\"\n  >\n    <AnswerStatusIcon\n      @status={{this.answer.computedStatus}}\n      class=\"flex flex-center nl1 nr1\"\n    />\n\n    <div class=\"text-ui\">\n      {{this.indexDot}}{{or @entity.header @entity.title \"no title\"}}\n    </div>\n  </UiButton>\n</div>\n", {"contents":"<div class={{if (not-eq @entity.id @result.entity.children.lastObject.id) \"bb1\"}}>\n  <UiButton\n    @appearance=\"link\"\n    @onClick={{this.openAudit}}\n    class=\"py1\"\n  >\n    <AnswerStatusIcon\n      @status={{this.answer.computedStatus}}\n      class=\"flex flex-center nl1 nr1\"\n    />\n\n    <div class=\"text-ui\">\n      {{this.indexDot}}{{or @entity.header @entity.title \"no title\"}}\n    </div>\n  </UiButton>\n</div>\n","moduleName":"babel/components/answer-log/diagnosis-list/answer-item/index.hbs","parseOptions":{"srcName":"babel/components/answer-log/diagnosis-list/answer-item/index.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { all, resolve } from 'rsvp';

export default class AnswerLogDiagnosisListAnswerItemComponent extends Component {
  /**
   * Arguments:
   * result (object)
   * entity (object)
   * index (number)
   */

  @service router;

  get indexDot() {
    if (this.args.entity.hideAssignmentSortNumbers) return null;
    const index = this.args.index;
    return index !== null && `${index + 1}. `;
  }

  @cached
  get answerProxy() {
    let promise = resolve();

    if (this.args.result) {
      promise = resolve(this.args.result.answers).then((answers) =>
        all(
          answers?.map((answer) =>
            resolve(answer.entity).then((entity) => {
              if (entity.id === this.args.entity.id) {
                return answer;
              }

              return null;
            })
          )
        ).then((results) => results.find((x) => x))
      );
    }

    return load(promise, this);
  }

  get answer() {
    return this.answerProxy.isResolved ? this.answerProxy.value : null;
  }

  @action
  openAudit() {
    return all([this.answer.entity, this.args.result.entity])
      .then(([answerEntity, resultEntity]) =>
        all([answerEntity, resultEntity, resultEntity.parent])
      )
      .then(([answerEntity, resultEntity, resultEntityParent]) =>
        this.router.transitionTo(
          'master.interactives.diagnoses.done.audit',
          resultEntityParent.id,
          resultEntity.id,
          this.args.result.id,
          {
            queryParams: { exercise_id: answerEntity.id },
          }
        )
      )
      .catch(() => {
        // do nothing
      });
  }
}
